const std_matrix = [
  [
    "AA",
    "AKs",
    "AQs",
    "AJs",
    "ATs",
    "A9s",
    "A8s",
    "A7s",
    "A6s",
    "A5s",
    "A4s",
    "A3s",
    "A2s",
  ],
  [
    "AKo",
    "KK",
    "KQs",
    "KJs",
    "KTs",
    "K9s",
    "K8s",
    "K7s",
    "K6s",
    "K5s",
    "K4s",
    "K3s",
    "K2s",
  ],
  [
    "AQo",
    "KQo",
    "QQ",
    "QJs",
    "QTs",
    "Q9s",
    "Q8s",
    "Q7s",
    "Q6s",
    "Q5s",
    "Q4s",
    "Q3s",
    "Q2s",
  ],
  [
    "AJo",
    "KJo",
    "QJo",
    "JJ",
    "JTs",
    "J9s",
    "J8s",
    "J7s",
    "J6s",
    "J5s",
    "J4s",
    "J3s",
    "J2s",
  ],
  [
    "ATo",
    "KTo",
    "QTo",
    "JTo",
    "TT",
    "T9s",
    "T8s",
    "T7s",
    "T6s",
    "T5s",
    "T4s",
    "T3s",
    "T2s",
  ],
  [
    "A9o",
    "K9o",
    "Q9o",
    "J9o",
    "T9o",
    "99",
    "98s",
    "97s",
    "96s",
    "95s",
    "94s",
    "93s",
    "92s",
  ],
  [
    "A8o",
    "K8o",
    "Q8o",
    "J8o",
    "T8o",
    "98o",
    "88",
    "87s",
    "86s",
    "85s",
    "84s",
    "83s",
    "82s",
  ],
  [
    "A7o",
    "K7o",
    "Q7o",
    "J7o",
    "T7o",
    "97o",
    "87o",
    "77",
    "76s",
    "75s",
    "74s",
    "73s",
    "72s",
  ],
  [
    "A6o",
    "K6o",
    "Q6o",
    "J6o",
    "T6o",
    "96o",
    "86o",
    "76o",
    "66",
    "65s",
    "64s",
    "63s",
    "62s",
  ],
  [
    "A5o",
    "K5o",
    "Q5o",
    "J5o",
    "T5o",
    "95o",
    "85o",
    "75o",
    "65o",
    "55",
    "54s",
    "53s",
    "52s",
  ],
  [
    "A4o",
    "K4o",
    "Q4o",
    "J4o",
    "T4o",
    "94o",
    "84o",
    "74o",
    "64o",
    "54o",
    "44",
    "43s",
    "42s",
  ],
  [
    "A3o",
    "K3o",
    "Q3o",
    "J3o",
    "T3o",
    "93o",
    "83o",
    "73o",
    "63o",
    "53o",
    "43o",
    "33",
    "32s",
  ],
  [
    "A2o",
    "K2o",
    "Q2o",
    "J2o",
    "T2o",
    "92o",
    "82o",
    "72o",
    "62o",
    "52o",
    "42o",
    "32o",
    "22",
  ],
];

////////////////////////////////////////////////////////////////////////

let positions = ["UTG", "UTG+1", "UTG+2", "LJ", "HJ", "CO", "BTN", "SB", "BB"];

////////////////////////////////////////////////////////////////////////

const utg_rfi = [
  ["R", "R", "R", "R", "R", "R", "F", "F", "F", "R", "F", "F", "F"],
  ["R", "R", "R", "R", "R", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "F", "R", "R", "R", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "R", "R", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "R", "R", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "R", "R", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "R", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "R", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "R", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const utg1_rfi = [
  ["R", "R", "R", "R", "R", "R", "R", "R", "R", "R", "F", "F", "F"],
  ["R", "R", "R", "R", "R", "R", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "R", "R", "R", "R", "R", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "F", "F", "R", "R", "R", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "R", "R", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "R", "R", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "R", "R", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "R", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "R", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const utg2_rfi = [
  ["R", "R", "R", "R", "R", "R", "R", "R", "R", "R", "R", "R", "R"],
  ["R", "R", "R", "R", "R", "R", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "R", "R", "R", "R", "R", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "F", "F", "R", "R", "R", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "R", "R", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "R", "R", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "R", "R", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "R", "R", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "R", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "R", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const lj_rfi = [
  ["R", "R", "R", "R", "R", "R", "R", "R", "R", "R", "R", "R", "R"],
  ["R", "R", "R", "R", "R", "R", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "R", "R", "R", "R", "R", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "F", "F", "R", "R", "R", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "F", "F", "F", "R", "R", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "R", "R", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "R", "R", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "R", "R", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "R", "R", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "R", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "R", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const hj_rfi = [
  ["R", "R", "R", "R", "R", "R", "R", "R", "R", "R", "R", "R", "R"],
  ["R", "R", "R", "R", "R", "R", "R", "F", "F", "F", "F", "F", "F"],
  ["R", "R", "R", "R", "R", "R", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "R", "R", "R", "R", "R", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "F", "F", "F", "R", "R", "R", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "R", "R", "R", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "R", "R", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "R", "R", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "R", "R", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "R", "R", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "R", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "R", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "R"],
];
const co_rfi = [
  ["R", "R", "R", "R", "R", "R", "R", "R", "R", "R", "R", "R", "R"],
  ["R", "R", "R", "R", "R", "R", "R", "R", "F", "F", "F", "F", "F"],
  ["R", "R", "R", "R", "R", "R", "R", "F", "F", "F", "F", "F", "F"],
  ["R", "R", "R", "R", "R", "R", "R", "F", "F", "F", "F", "F", "F"],
  ["R", "R", "R", "R", "R", "R", "R", "F", "F", "F", "F", "F", "F"],
  ["R", "F", "F", "F", "F", "R", "R", "R", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "R", "R", "R", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "R", "R", "R", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "R", "R", "R", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "R", "R", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "R", "R", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "R", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "R"],
];
const btn_rfi = [
  ["R", "R", "R", "R", "R", "R", "R", "R", "R", "R", "R", "R", "R"],
  ["R", "R", "R", "R", "R", "R", "R", "R", "R", "R", "R", "R", "R"],
  ["R", "R", "R", "R", "R", "R", "R", "R", "R", "R", "R", "R", "R"],
  ["R", "R", "R", "R", "R", "R", "R", "R", "R", "F", "F", "F", "F"],
  ["R", "R", "R", "R", "R", "R", "R", "R", "R", "F", "F", "F", "F"],
  ["R", "R", "R", "R", "R", "R", "R", "R", "R", "F", "F", "F", "F"],
  ["R", "R", "R", "R", "R", "R", "R", "R", "R", "R", "F", "F", "F"],
  ["R", "R", "F", "F", "F", "R", "R", "R", "R", "R", "R", "F", "F"],
  ["R", "F", "F", "F", "F", "F", "F", "R", "R", "R", "R", "F", "F"],
  ["R", "F", "F", "F", "F", "F", "F", "F", "F", "R", "R", "R", "F"],
  ["R", "F", "F", "F", "F", "F", "F", "F", "F", "F", "R", "R", "F"],
  ["R", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "R", "R"],
  ["R", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "R"],
];
const sb_rfi = [
  ["C", "R", "R", "R", "R", "C", "C", "C", "C", "C", "C", "C", "C"],
  ["C", "C", "R", "R", "C", "C", "C", "C", "C", "C", "C", "C", "C"],
  ["R", "R", "R", "R", "C", "C", "C", "C", "C", "C", "C", "C", "C"],
  ["R", "R", "C", "R", "C", "C", "C", "C", "C", "C", "B", "B", "B"],
  ["R", "C", "C", "C", "R", "C", "C", "C", "C", "B", "B", "F", "F"],
  ["C", "C", "C", "C", "C", "R", "C", "C", "C", "B", "B", "F", "F"],
  ["C", "C", "C", "C", "C", "C", "R", "C", "C", "B", "B", "F", "F"],
  ["C", "C", "C", "C", "C", "C", "C", "C", "C", "C", "B", "F", "F"],
  ["C", "C", "C", "B", "B", "B", "B", "C", "C", "C", "C", "B", "F"],
  ["C", "C", "B", "F", "F", "F", "F", "F", "C", "C", "C", "B", "F"],
  ["C", "C", "B", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F"],
  ["C", "B", "B", "F", "F", "F", "F", "F", "F", "F", "F", "C", "C"],
  ["C", "B", "B", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C"],
];
const rfi = [
  utg_rfi,
  utg1_rfi,
  utg2_rfi,
  lj_rfi,
  hj_rfi,
  co_rfi,
  btn_rfi,
  sb_rfi,
  sb_rfi,
];

////////////////////////////////////////////////////////////////////////

const vs_utg_utg1 = [
  ["R", "R", "C", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "R", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "R", "C", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const vs_utg_utg2 = [
  ["R", "R", "C", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "R", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "R", "C", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const vs_utg_lj = [
  ["R", "R", "C", "C", "B", "F", "F", "F", "F", "B", "F", "F", "F"],
  ["R", "R", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "R", "C", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const vs_utg_hj = [
  ["R", "R", "C", "C", "B", "F", "F", "F", "F", "B", "F", "F", "F"],
  ["R", "R", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "R", "C", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const vs_utg_co = [
  ["R", "R", "R", "C", "C", "F", "F", "F", "F", "B", "B", "F", "F"],
  ["R", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const vs_utg_btn = [
  ["R", "R", "C", "C", "C", "F", "F", "F", "F", "B", "B", "F", "F"],
  ["R", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "B", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C"],
];
const vs_utg_sb = [
  ["R", "R", "C", "C", "C", "F", "F", "F", "F", "B", "F", "F", "F"],
  ["C", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "F", "R", "C", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const vs_utg_bb = [
  ["R", "R", "R", "C", "C", "C", "C", "C", "C", "C", "C", "C", "C"],
  ["C", "R", "C", "C", "C", "C", "C", "C", "C", "C", "F", "F", "F"],
  ["C", "C", "R", "C", "C", "C", "C", "C", "F", "F", "F", "F", "F"],
  ["C", "C", "C", "C", "C", "C", "C", "C", "F", "F", "F", "F", "F"],
  ["C", "C", "C", "C", "C", "C", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "C", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "B", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "B", "B", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C"],
];
const vs_utg = [
  vs_utg_utg1,
  vs_utg_utg2,
  vs_utg_lj,
  vs_utg_hj,
  vs_utg_co,
  vs_utg_btn,
  vs_utg_sb,
  vs_utg_bb,
];

const vs_utg1_utg2 = [
  ["R", "R", "C", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "R", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "R", "C", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const vs_utg1_lj = [
  ["R", "R", "C", "C", "B", "F", "F", "F", "F", "B", "F", "F", "F"],
  ["R", "R", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "R", "C", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const vs_utg1_hj = [
  ["R", "R", "C", "C", "B", "F", "F", "F", "F", "B", "F", "F", "F"],
  ["R", "R", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "R", "C", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const vs_utg1_co = [
  ["R", "R", "R", "C", "C", "F", "F", "F", "F", "B", "B", "F", "F"],
  ["R", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const vs_utg1_btn = [
  ["R", "R", "C", "C", "C", "F", "F", "F", "F", "B", "B", "B", "B"],
  ["R", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "B", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C"],
];
const vs_utg1_sb = [
  ["R", "R", "C", "C", "C", "F", "F", "F", "F", "B", "F", "F", "F"],
  ["C", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "F", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const vs_utg1_bb = [
  ["R", "R", "R", "C", "C", "C", "C", "C", "C", "C", "C", "C", "C"],
  ["C", "R", "C", "C", "C", "C", "C", "C", "C", "C", "F", "F", "F"],
  ["C", "C", "R", "C", "C", "C", "C", "C", "F", "F", "F", "F", "F"],
  ["C", "C", "C", "C", "C", "C", "C", "C", "F", "F", "F", "F", "F"],
  ["C", "C", "C", "C", "C", "C", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "C", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "B", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "B", "B", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C"],
];
const vs_utg1 = [
  vs_utg1_utg2,
  vs_utg1_lj,
  vs_utg1_hj,
  vs_utg1_co,
  vs_utg1_btn,
  vs_utg1_sb,
  vs_utg1_bb,
];

const vs_utg2_lj = [
  ["R", "R", "C", "C", "B", "F", "F", "F", "F", "B", "F", "F", "F"],
  ["R", "R", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "R", "C", "R", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const vs_utg2_hj = [
  ["R", "R", "C", "C", "B", "F", "F", "F", "F", "B", "B", "B", "F"],
  ["R", "R", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "R", "C", "R", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const vs_utg2_co = [
  ["R", "R", "R", "C", "C", "F", "F", "F", "F", "B", "B", "B", "B"],
  ["R", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const vs_utg2_btn = [
  ["R", "R", "R", "C", "C", "B", "F", "F", "F", "B", "B", "B", "B"],
  ["R", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "C", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "F", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C"],
];
const vs_utg2_sb = [
  ["R", "R", "R", "C", "C", "F", "F", "F", "F", "B", "B", "F", "F"],
  ["R", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "F", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const vs_utg2_bb = [
  ["R", "R", "R", "C", "C", "C", "C", "C", "C", "C", "C", "C", "C"],
  ["R", "R", "C", "C", "C", "C", "C", "C", "C", "C", "C", "F", "F"],
  ["C", "C", "R", "C", "C", "C", "C", "C", "C", "F", "F", "F", "F"],
  ["C", "C", "C", "R", "C", "C", "C", "C", "C", "F", "F", "F", "F"],
  ["C", "C", "C", "C", "C", "C", "C", "C", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "C", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "B", "B", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "B", "B", "B", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "B", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "B", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C"],
];
const vs_utg2 = [
  vs_utg2_lj,
  vs_utg2_hj,
  vs_utg2_co,
  vs_utg2_btn,
  vs_utg2_sb,
  vs_utg2_bb,
];

const vs_lj_hj = [
  ["R", "R", "R", "R", "C", "F", "F", "F", "F", "B", "B", "B", "B"],
  ["R", "R", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "B", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const vs_lj_co = [
  ["R", "R", "R", "R", "C", "F", "F", "F", "F", "B", "B", "B", "B"],
  ["R", "R", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "B", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const vs_lj_btn = [
  ["R", "R", "R", "C", "C", "C", "B", "B", "B", "B", "B", "B", "B"],
  ["R", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "C", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "F", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C"],
];
const vs_lj_sb = [
  ["R", "R", "R", "C", "C", "F", "F", "F", "F", "B", "B", "F", "F"],
  ["R", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "F", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const vs_lj_bb = [
  ["R", "R", "R", "R", "C", "C", "C", "C", "C", "C", "C", "C", "C"],
  ["R", "R", "R", "C", "C", "C", "C", "C", "C", "C", "C", "C", "C"],
  ["C", "C", "R", "C", "C", "C", "C", "C", "C", "C", "C", "F", "F"],
  ["C", "C", "C", "R", "C", "C", "C", "C", "C", "C", "F", "F", "F"],
  ["C", "C", "C", "C", "R", "C", "C", "C", "C", "C", "F", "F", "F"],
  ["B", "F", "F", "F", "F", "C", "C", "C", "C", "C", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "C", "B", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "C", "B", "B", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "B", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "B", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C"],
];
const vs_lj = [vs_lj_hj, vs_lj_co, vs_lj_btn, vs_lj_sb, vs_lj_bb];

const vs_hj_co = [
  ["R", "R", "R", "R", "C", "B", "B", "B", "B", "B", "B", "B", "B"],
  ["R", "R", "R", "C", "C", "B", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "B", "R", "C", "C", "B", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "C", "C", "B", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const vs_hj_btn = [
  ["R", "R", "R", "R", "C", "C", "B", "B", "B", "B", "B", "B", "B"],
  ["R", "R", "C", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "C", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "F", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "F", "C", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "B", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "C", "B", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C"],
];
const vs_hj_sb = [
  ["R", "R", "R", "C", "C", "B", "F", "F", "F", "B", "B", "B", "F"],
  ["R", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "F", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const vs_hj_bb = [
  ["R", "R", "R", "R", "C", "C", "C", "C", "C", "C", "C", "C", "C"],
  ["R", "R", "R", "C", "C", "C", "C", "C", "C", "C", "C", "C", "C"],
  ["R", "C", "R", "C", "C", "C", "C", "C", "C", "C", "C", "F", "F"],
  ["C", "C", "C", "R", "C", "C", "C", "C", "C", "C", "F", "F", "F"],
  ["C", "C", "C", "C", "R", "C", "C", "C", "C", "C", "F", "F", "F"],
  ["B", "F", "F", "F", "F", "C", "C", "C", "C", "C", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "C", "B", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "B", "B", "B", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "B", "B", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "B", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C"],
];
const vs_hj = [vs_hj_co, vs_hj_btn, vs_hj_sb, vs_hj_bb];

const vs_co_btn = [
  ["R", "R", "R", "R", "C", "C", "B", "B", "B", "C", "C", "B", "B"],
  ["R", "R", "C", "C", "C", "C", "B", "F", "F", "F", "F", "F", "F"],
  ["R", "C", "R", "C", "C", "C", "B", "F", "F", "F", "F", "F", "F"],
  ["C", "C", "B", "C", "C", "C", "B", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "F", "F", "C", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "F", "F", "C", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "B", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "C", "B", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "B", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C"],
];
const vs_co_sb = [
  ["R", "R", "R", "R", "R", "B", "B", "B", "B", "B", "B", "B", "B"],
  ["R", "R", "R", "B", "B", "B", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "B", "R", "B", "B", "B", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "F", "F", "R", "B", "B", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "R", "B", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "B", "B", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "B", "B", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "B", "B", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "B", "B", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "B", "B", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "B", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const vs_co_bb = [
  ["R", "R", "R", "R", "R", "C", "C", "C", "C", "C", "C", "C", "C"],
  ["R", "R", "R", "R", "C", "C", "C", "C", "C", "C", "C", "C", "C"],
  ["R", "R", "R", "R", "C", "C", "C", "C", "C", "C", "C", "F", "F"],
  ["R", "C", "C", "R", "C", "C", "C", "C", "C", "C", "C", "F", "F"],
  ["C", "C", "C", "C", "R", "C", "C", "C", "C", "C", "C", "F", "F"],
  ["C", "C", "C", "C", "C", "C", "C", "C", "C", "C", "C", "F", "F"],
  ["C", "F", "F", "F", "F", "C", "C", "C", "C", "B", "B", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "B", "B", "B", "B", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "B", "B", "F"],
  ["C", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "B", "B"],
  ["B", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "B"],
  ["B", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C"],
];
const vs_co = [vs_co_btn, vs_co_sb, vs_co_bb];

const vs_btn_sb = [
  ["R", "R", "R", "R", "R", "B", "B", "B", "B", "B", "B", "B", "B"],
  ["R", "R", "R", "R", "B", "B", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "R", "R", "B", "B", "B", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "B", "B", "R", "B", "B", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "F", "R", "B", "B", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "B", "B", "B", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "B", "B", "B", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "B", "B", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "B", "B", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "B", "B", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "B", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "B", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "B"],
];
const vs_btn_bb = [
  ["R", "R", "R", "R", "R", "C", "C", "C", "C", "C", "C", "C", "C"],
  ["R", "R", "R", "R", "R", "C", "C", "C", "C", "C", "C", "C", "C"],
  ["R", "R", "R", "R", "R", "C", "C", "C", "C", "C", "C", "C", "C"],
  ["R", "C", "C", "R", "R", "C", "C", "C", "C", "C", "C", "C", "C"],
  ["C", "C", "C", "C", "R", "C", "C", "C", "C", "C", "C", "C", "C"],
  ["C", "C", "C", "C", "C", "R", "C", "C", "C", "C", "C", "C", "C"],
  ["C", "C", "C", "C", "C", "C", "C", "C", "C", "C", "C", "C", "C"],
  ["C", "C", "C", "C", "C", "C", "C", "C", "B", "B", "B", "B", "C"],
  ["C", "C", "B", "C", "C", "C", "C", "C", "C", "B", "B", "B", "C"],
  ["C", "B", "B", "F", "F", "F", "F", "B", "B", "C", "B", "B", "B"],
  ["C", "B", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "B"],
  ["B", "B", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B"],
  ["B", "B", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C"],
];
const vs_btn = [vs_btn_sb, vs_btn_bb];

const vs_sb_bb = [
  ["R", "R", "R", "R", "R", "C", "C", "C", "C", "C", "C", "C", "C"],
  ["R", "R", "R", "R", "R", "C", "C", "C", "C", "C", "C", "C", "C"],
  ["R", "R", "R", "R", "R", "C", "C", "C", "C", "C", "C", "C", "C"],
  ["R", "C", "C", "R", "R", "B", "C", "C", "C", "C", "C", "C", "C"],
  ["C", "C", "C", "C", "R", "B", "B", "C", "C", "C", "C", "C", "C"],
  ["C", "C", "C", "C", "C", "R", "B", "C", "C", "C", "C", "C", "C"],
  ["C", "C", "C", "C", "C", "C", "C", "B", "C", "C", "C", "C", "C"],
  ["C", "C", "C", "C", "C", "C", "C", "C", "B", "C", "C", "C", "C"],
  ["C", "C", "C", "C", "C", "C", "C", "B", "C", "B", "C", "C", "C"],
  ["C", "C", "C", "B", "B", "F", "F", "B", "B", "C", "B", "C", "C"],
  ["C", "C", "B", "F", "F", "F", "F", "F", "B", "B", "C", "C", "C"],
  ["C", "B", "B", "F", "F", "F", "F", "F", "F", "F", "F", "C", "C"],
  ["B", "B", "B", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C"],
];
const vs_sb = [vs_sb_bb];

const vs_rfi = [vs_utg, vs_utg1, vs_utg2, vs_lj, vs_hj, vs_co, vs_btn, vs_sb];

////////////////////////////////////////////////////////////////////////

const utg_vs_utg1_3bet = [
  ["R", "R", "C", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "R", "C", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const utg_vs_utg2_3bet = [
  ["R", "R", "C", "C", "B", "B", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "R", "C", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const utg_vs_lj_3bet = [
  ["R", "R", "C", "C", "B", "B", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "R", "C", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const utg_vs_hj_3bet = [
  ["R", "R", "C", "C", "B", "B", "F", "F", "F", "B", "F", "F", "F"],
  ["R", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "R", "C", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const utg_vs_co_3bet = [
  ["R", "R", "C", "C", "C", "B", "F", "F", "F", "B", "F", "F", "F"],
  ["R", "R", "C", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "F", "R", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const utg_vs_btn_3bet = [
  ["R", "R", "C", "C", "C", "B", "F", "F", "F", "B", "F", "F", "F"],
  ["R", "R", "C", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "F", "R", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const utg_vs_sb_3bet = [
  ["R", "R", "C", "C", "C", "B", "F", "F", "F", "B", "F", "F", "F"],
  ["R", "R", "C", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "F", "R", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "B", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const utg_vs_bb_3bet = [
  ["R", "R", "C", "C", "C", "B", "F", "F", "F", "B", "F", "F", "F"],
  ["R", "R", "C", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "F", "R", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "B", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const utg_rfi_vs_3bet = [
  utg_vs_utg1_3bet,
  utg_vs_utg2_3bet,
  utg_vs_lj_3bet,
  utg_vs_hj_3bet,
  utg_vs_co_3bet,
  utg_vs_btn_3bet,
  utg_vs_btn_3bet,
  utg_vs_sb_3bet,
  utg_vs_bb_3bet,
];

const utg1_vs_utg2_3bet = [
  ["R", "R", "C", "C", "B", "B", "F", "F", "F", "B", "F", "F", "F"],
  ["R", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const utg1_vs_lj_3bet = [
  ["R", "R", "C", "C", "C", "B", "F", "F", "F", "B", "B", "F", "F"],
  ["R", "R", "C", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "F", "C", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const utg1_vs_hj_3bet = [
  ["R", "R", "C", "C", "C", "B", "F", "F", "F", "B", "B", "F", "F"],
  ["R", "R", "C", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "C", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const utg1_vs_co_3bet = [
  ["R", "R", "C", "C", "C", "B", "F", "F", "F", "B", "B", "F", "F"],
  ["R", "R", "C", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "C", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const utg1_vs_btn_3bet = [
  ["R", "R", "C", "C", "C", "B", "F", "F", "F", "B", "B", "F", "F"],
  ["R", "R", "C", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "C", "C", "B", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "F", "F", "C", "C", "B", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const utg1_vs_sb_3bet = [
  ["R", "R", "C", "C", "C", "B", "B", "F", "F", "B", "B", "F", "F"],
  ["R", "R", "C", "C", "C", "B", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "F", "F", "C", "C", "B", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const utg1_vs_bb_3bet = [
  ["R", "R", "C", "C", "C", "B", "B", "F", "F", "B", "B", "F", "F"],
  ["R", "R", "C", "C", "C", "B", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "F", "F", "C", "C", "B", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const utg1_rfi_vs_3bet = [
  utg1_vs_utg2_3bet,
  utg1_vs_lj_3bet,
  utg1_vs_hj_3bet,
  utg1_vs_co_3bet,
  utg1_vs_btn_3bet,
  utg1_vs_sb_3bet,
  utg1_vs_bb_3bet,
];

const utg2_vs_lj_3bet = [
  ["R", "R", "C", "C", "C", "B", "F", "F", "F", "B", "B", "B", "B"],
  ["R", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const utg2_vs_hj_3bet = [
  ["R", "R", "C", "C", "C", "B", "F", "F", "F", "B", "B", "B", "B"],
  ["R", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const utg2_vs_co_3bet = [
  ["R", "R", "C", "C", "C", "B", "B", "F", "F", "B", "B", "B", "B"],
  ["R", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const utg2_vs_btn_3bet = [
  ["R", "R", "C", "C", "C", "B", "B", "F", "F", "B", "B", "B", "B"],
  ["R", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const utg2_vs_sb_3bet = [
  ["R", "R", "C", "C", "C", "B", "B", "F", "F", "B", "B", "B", "B"],
  ["R", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const utg2_vs_bb_3bet = [
  ["R", "R", "C", "C", "C", "B", "B", "F", "F", "B", "B", "B", "B"],
  ["R", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const utg2_rfi_vs_3bet = [
  utg2_vs_lj_3bet,
  utg2_vs_hj_3bet,
  utg2_vs_co_3bet,
  utg2_vs_btn_3bet,
  utg2_vs_sb_3bet,
  utg2_vs_bb_3bet,
];

const lj_vs_hj_3bet = [
  ["R", "R", "C", "C", "C", "B", "B", "F", "F", "B", "B", "B", "B"],
  ["R", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const lj_vs_co_3bet = [
  ["R", "R", "C", "C", "C", "B", "B", "F", "F", "B", "B", "B", "B"],
  ["R", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "R", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const lj_vs_btn_3bet = [
  ["R", "R", "C", "C", "C", "B", "B", "B", "F", "B", "B", "B", "B"],
  ["R", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "R", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const lj_vs_sb_3bet = [
  ["R", "R", "C", "C", "C", "B", "B", "B", "B", "B", "B", "B", "B"],
  ["R", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "C", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "F", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const lj_vs_bb_3bet = [
  ["R", "R", "C", "C", "C", "B", "B", "B", "B", "B", "B", "B", "B"],
  ["R", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "R", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const lj_rfi_vs_3bet = [
  lj_vs_hj_3bet,
  lj_vs_co_3bet,
  lj_vs_btn_3bet,
  lj_vs_sb_3bet,
  lj_vs_bb_3bet,
];

const hj_vs_co_3bet = [
  ["R", "R", "C", "C", "C", "B", "B", "B", "B", "B", "B", "B", "B"],
  ["R", "R", "C", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "C", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "F", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const hj_vs_btn_3bet = [
  ["R", "R", "C", "C", "C", "B", "B", "B", "B", "B", "B", "B", "B"],
  ["R", "R", "C", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "C", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "F", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const hj_vs_sb_3bet = [
  ["R", "R", "C", "C", "C", "B", "B", "B", "B", "B", "B", "B", "B"],
  ["R", "R", "C", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "C", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "F", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const hj_vs_bb_3bet = [
  ["R", "R", "C", "C", "C", "B", "B", "B", "B", "B", "B", "B", "B"],
  ["R", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "R", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const hj_rfi_vs_3bet = [
  hj_vs_co_3bet,
  hj_vs_btn_3bet,
  hj_vs_sb_3bet,
  hj_vs_bb_3bet,
];

const co_vs_btn_3bet = [
  ["R", "R", "C", "C", "C", "C", "B", "B", "B", "C", "B", "B", "B"],
  ["R", "R", "C", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "C", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "F", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "F", "R", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "B", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "B", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "C", "B", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const co_vs_sb_3bet = [
  ["R", "R", "C", "C", "C", "C", "B", "B", "B", "C", "B", "B", "B"],
  ["R", "R", "C", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "C", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "F", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "F", "R", "C", "C", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "B", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "B", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "C", "B", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const co_vs_bb_3bet = [
  ["R", "R", "C", "C", "C", "C", "B", "F", "F", "C", "B", "B", "B"],
  ["R", "R", "C", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "C", "R", "C", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "B", "F", "R", "C", "C", "F", "F", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "F", "R", "C", "B", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "C", "B", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "C", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const co_rfi_vs_3bet = [co_vs_btn_3bet, co_vs_sb_3bet, co_vs_bb_3bet];

const btn_vs_sb_3bet = [
  ["R", "R", "R", "R", "C", "C", "C", "C", "C", "C", "C", "C", "C"],
  ["R", "R", "C", "C", "C", "C", "C", "C", "B", "B", "B", "F", "F"],
  ["R", "C", "R", "C", "C", "C", "C", "B", "F", "F", "F", "F", "F"],
  ["C", "C", "C", "R", "C", "C", "C", "B", "F", "F", "F", "F", "F"],
  ["C", "C", "B", "B", "R", "C", "C", "C", "F", "F", "F", "F", "F"],
  ["C", "B", "F", "F", "F", "R", "C", "C", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "F", "F", "F", "C", "C", "B", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "C", "B", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F"],
  ["B", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F"],
  ["B", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F"],
  ["B", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C"],
];
const btn_vs_bb_3bet = [
  ["R", "R", "R", "R", "C", "C", "C", "C", "C", "C", "C", "C", "C"],
  ["R", "R", "C", "C", "C", "C", "C", "C", "B", "B", "B", "F", "F"],
  ["R", "C", "R", "C", "C", "C", "C", "B", "F", "F", "F", "F", "F"],
  ["C", "C", "C", "R", "C", "C", "C", "B", "F", "F", "F", "F", "F"],
  ["C", "C", "B", "B", "R", "C", "C", "C", "F", "F", "F", "F", "F"],
  ["C", "B", "F", "F", "F", "R", "C", "C", "F", "F", "F", "F", "F"],
  ["B", "F", "F", "F", "F", "F", "C", "C", "B", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "C", "C", "B", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F", "F"],
  ["B", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F", "F"],
  ["B", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "B", "F"],
  ["B", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C"],
];
const btn_rfi_vs_3bet = [btn_vs_sb_3bet, btn_vs_bb_3bet];

const sb_vs_bb_3bet = [
  ["F", "R", "R", "R", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "C", "C", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "C", "R", "C", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["C", "C", "F", "R", "F", "F", "F", "F", "F", "F", "B", "F", "F"],
  ["C", "F", "F", "F", "C", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "C", "F", "F", "F", "C", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "C", "F", "F", "C", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "B", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "B", "F", "F", "F", "F", "F", "F", "F", "F", "C", "F"],
  ["F", "B", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "B", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const sb_rfi_vs_3bet = [sb_vs_bb_3bet];

const rfi_vs_3bet = [
  utg_rfi_vs_3bet,
  utg1_rfi_vs_3bet,
  utg2_rfi_vs_3bet,
  lj_rfi_vs_3bet,
  hj_rfi_vs_3bet,
  co_rfi_vs_3bet,
  btn_rfi_vs_3bet,
  sb_rfi_vs_3bet,
];

////////////////////////////////////////////////////////////////////////

const utg2_vs_3betcold = [
  ["R", "R", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "R", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const lj_vs_3betcold = [
  ["R", "R", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "R", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "R", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const hj_vs_3betcold = [
  ["R", "R", "R", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "R", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "R", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const co_vs_3betcold = [
  ["R", "R", "R", "F", "F", "F", "F", "F", "R", "F", "F", "F", "F"],
  ["R", "R", "R", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "R", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const btn_vs_3betcold = [
  ["R", "R", "R", "F", "F", "F", "F", "F", "R", "F", "F", "F", "F"],
  ["R", "R", "R", "R", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "R", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "R", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const sb_vs_3betcold = [
  ["R", "R", "R", "F", "F", "F", "F", "F", "R", "F", "F", "F", "F"],
  ["R", "R", "R", "R", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "F", "R", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "R", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];
const bb_vs_3betcold = [
  ["R", "R", "R", "R", "F", "F", "F", "F", "R", "F", "F", "F", "F"],
  ["R", "R", "R", "R", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["R", "F", "R", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "R", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
  ["F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F", "F"],
];

const vs_3betcold = [
  utg2_vs_3betcold,
  lj_vs_3betcold,
  hj_vs_3betcold,
  co_vs_3betcold,
  btn_vs_3betcold,
  sb_vs_3betcold,
  bb_vs_3betcold,
];

////////////////////////////////////////////////////////////////////////

let tutorial = [
  {
    target: ".action-joyride",
    content:
      "Select your position and record other players’ actions (R = Raise, O = Open, F = Fold) here. Degen101 currently supports RFI, vs RFI, RFI vs 3-bet, and vs 3-bet cold ranges.",
  },
  {
    target: ".chart-title-joyride",
    content: "This is the chart corresponding to the table action.",
  },
  {
    target: ".players-joyride",
    content:
      "You can adjust the number of players here. The maximum number of players is 9, and the minimum number of players is 3.",
  },
  {
    target: ".matrix-joyride",
    content:
      "You can view hand suggestions here! There are 4 categories of suggestions: Raise, Call, Bluff Raise, and Fold.",
  },
  {
    target: ".right-panel-joyride",
    content: "More information about the chart can be found here.",
  },
  {
    target: ".range-constructor-button-joyride",
    content:
      "To edit our charts or construct your own ranges, navigate to the range constructor page.",
  },
  {
    target: ".file-tree-joyride",
    content:
      "Navigate through the different charts here and select the one you want to edit.",
  },
  {
    target: ".legend-joyride",
    content: "Select Raise, Call, or Bluff to edit the chart accordingly.",
  },
  {
    target: ".select-matrix-joyride",
    content: "Finally, you can edit your chart here!",
  },
];

////////////////////////////////////////////////////////////////////////

export {
  tutorial,
  vs_3betcold,
  rfi_vs_3bet,
  vs_rfi,
  rfi,
  std_matrix,
  positions,
};
